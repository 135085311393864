import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import Meta from "../../components/content/Meta";
import contentfulComponentMap from "../../utils/contentfulComponentMap";
import { SettingsContext } from "../../context/SettingsContext";
import S from "./styles";

export const query = graphql`
  query PageQuery($contentful_id: String!) {
    pageData: contentfulPage(contentful_id: { eq: $contentful_id }) {
      contentful_id
      title
      metadata {
        ...Metadata
      }
      content {
        ...AlternatingPanels
        ...ArticlesSettings
        ...Awards
        ...ClientExperiencesSettings
        ...Clients
        ...ContactContent
        ...ContentSpacer
        ...ExcerptColumns
        ...FeaturedClientExperience
        ...FeaturedContent
        ...HeadingContentColumns
        ...IconFeatures
        ...ImageTitleContentSelector
        ...ImageTitleTiles
        ...IntroContent
        ...JobAdderWidget
        ...PageHeader
        ...SimplePageHeader
        ...TeamSection
        ...UnderlinedHeading
      }
    }
  }
`;

const Page = ({ data, pathContext }) => {
  const { setSettings } = useContext(SettingsContext);

  if (!data || !data.pageData) return null;

  const { content = [], metadata = {} } = data.pageData;

  useEffect(() => {
    if (pathContext.settings) {
      setSettings(pathContext.settings);
    }
  }, []);

  return (
    <Layout pathContext={pathContext}>
      <Meta
        defaults={pathContext.settings.defaultMetadata}
        meta={{ ...metadata }}
      />
      <S.Content>
        {content &&
          content.map((component, index) => {
            const Component = contentfulComponentMap[component?.internal?.type];

            if (!Component) return null;

            return (
              <Component key={index} {...component} pathContext={pathContext} />
            );
          })}
      </S.Content>
    </Layout>
  );
};

export default Page;
